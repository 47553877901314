@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Roboto', sans-serif;
}

.dot {
    height: 25px;
    width: 25px;
    background-color: #bbb;
    border-radius: 50%;
    display: inline-block;
}

.leaflet-control-attribution > a:first-child {
    display: none !important;
}

.leaflet-control-attribution > span {
    display: none !important;
}

.leaflet-top {
    top: 5rem !important;
}

.leaflet-popup-content {
	margin: 0 !important;
}

.leaflet-popup-content-wrapper, .leaflet-popup-tip {
	background: unset !important;
    box-shadow: unset !important;
    padding: 0 !important;
}

.leaflet-container a.leaflet-popup-close-button {
    color: #fff !important;
}

.leaflet-container a.leaflet-popup-close-button {
    font: 36px/24px Tahoma, Verdana, sans-serif !important;
}

.leaflet-popup {
    bottom: -100px !important;
}

@media (min-height: 496px) {
	.bottom-pane {
		height: 476px;
		margin-top: 0;
		top: calc(100% - 228px);
	}

    .q3XXQc {
        height: 48px;
    }
}



.leaflet-touch .leaflet-control-attribution,
.leaflet-touch .leaflet-control-layers,
.leaflet-touch .leaflet-bar {
    display: flex;
    padding: 0 20;
    flex-wrap: wrap;
    flex-direction: row;
    align-content: space-around;
    justify-content: flex-start;
    align-items: center;
}

/* stylesheet://localhost/style#14436.125 */

.leaflet-touch .leaflet-control-attribution,
.leaflet-touch .leaflet-control-layers,
.leaflet-touch .leaflet-bar {
    background: aqua;
}

/* stylesheet://localhost/style#14436.138 */

.leaflet-touch .leaflet-control-attribution,
.leaflet-touch .leaflet-control-layers,
.leaflet-touch .leaflet-bar {
    display: flex;
    padding: 0 20;
    flex-wrap: wrap;
    flex-direction: row;
    align-content: space-around;
    justify-content: flex-start;
    align-items: center;
}

/* stylesheet://localhost/style#14436.170 */

.leaflet-control-attribution a {
    /* padding: 13px; */
    margin-left: 5px;
}

/* stylesheet://localhost/style#14436.171 */

.leaflet-control-attribution>a:first-child {
    padding: 28px;
}